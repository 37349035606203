import { useState, useEffect, useCallback } from 'react';
import { ReactFlowProvider, useStoreState } from 'react-flow-renderer';
import ControlPanel from './ControlPanel';
import statusesToEdgeList from './helpers/statusesToEdgeList';
import statusesToNodeList from './helpers/statusesToNodeList';
import LayoutFlow from './LayoutFlow';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import getProductList from '../../api/utils/product/getProductList';
import { getNotifyMessageList } from '../../api/utils/merchant/notifymessage/notifyMessages';
import { isNode, getOutgoers } from 'react-flow-renderer';
import getStatus from './helpers/getStatus';
import prepareToPublish from './helpers/prepareToPublish';
import { MenuItem, Select, ListSubheader } from '@mui/material';
import { getMerchantOrderProcessList } from '../../api/utils/orderModel/getMerchantOrderProcessList';
import getAllowedRegStatuses from '../../api/utils/orderModel/getAllowedRegStatuses';
import getAdminRolesList from '../../api/utils/role/getAdminRolesList';
import UserInfoContext from '../../contexts/UserInfoContext';
import { useContext } from 'react';
import Wait from '../UI/Wait';
import { SERVER_APP_ID, SERVER_OWNER_ID } from '../../config';

const OrderModelView = (props) => {
    const { app, merchant, setInfo } = useContext(UserInfoContext);
    const [orderModelsList, setOrderModelsList] = useState(null);
    const { orderModel, onSave } = props;
    const [nodes, setNodes] = useState(null);

    const [orderModelName, setOrderModelName] = useState(null);

    const [rfInstance, setRfInstance] = useState(null);

    const [products, setProducts] = useState({
        productList: [],
        productTypes: {},
    });

    const [messages, setMessages] = useState([]);
    const [orderType, setOrderType] = useState('');
    const [destinationGroup, setDestinationGroup] = useState(null);

    const [roles, setRoles] = useState([]);
    const [rolesOptions, setRolesOptions] = useState([
        {
            label: 'None',
            value: -1,
        },
    ]);

    const [loading, setLoading] = useState(false);

    // Statuses for User Registration flow
    const [allowedUserRegStatuses, setAllowedUserRegStatuses] = useState([]);
    // Statuses for Merchant Registration flow
    const [allowedMerchantRegStatuses, setAllowedMerchantRegStatuses] =
        useState([]);

    const handleChangeType = (event) => {
        const newOrderType = event.target.value;

        setOrderType(newOrderType || '');
    };

    const resetNodes = () => {
        if (!orderModel) return;
        const { statuses, order_type } = orderModel;
        const nodeList = statusesToNodeList(statuses);
        const edgesList = statusesToEdgeList(statuses);
        setNodes([...nodeList, ...edgesList]);
    };

    const handleDeleteNode = (id) => {
        if (!orderModel || !id) return;
        const filteredNodes = nodes.filter(
            (item) =>
                item?.id !== id && item?.source !== id && item?.target !== id
        );
        setNodes(filteredNodes);
    };

    useEffect(() => {
        if (!orderModel) return;
        const { statuses, order_type } = orderModel;
        const nodeList = statusesToNodeList(statuses);
        const edgesList = statusesToEdgeList(statuses);
        setOrderModelName(orderModel.name);
        setNodes([...nodeList, ...edgesList]);
        setOrderType(order_type || '');
        if (rolesOptions.length) {
            const currentDestGroup = rolesOptions.find(
                (item) => item.value === orderModel.destination_group
            );
            setDestinationGroup(currentDestGroup);
        }
    }, [orderModel, rolesOptions]);

    const onSetNodes = (data) => {
        const upd_nodes = nodes.map((t1) => ({
            ...t1,
            ...data.find((t2) => t2.id === t1.id),
        }));
        const new_nodes = data.filter(
            (t1) => !nodes.some((t2) => t2.id === t1.id)
        );
        const result = [...upd_nodes, ...new_nodes];
        setNodes(result);
        onPublish(result);
    };

    const fetchOrderModelList = async () => {
        setLoading(true);

        const resultHandler = (data) => {
            setLoading(false);
            if (data && data.status === 'accept') {
                setOrderModelsList(data.orderprocesses);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(
                error?.description || 'Error getting allowed statuses',
                'Error',
                4000
            );
            setLoading(false);
            setOrderModelsList([]);
        };

        try {
            const res = await getMerchantOrderProcessList(
                {
                    merchantId: SERVER_OWNER_ID,
                    appid: SERVER_APP_ID,
                    order_type: 'service_fee',
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log('Error during the request: ', error);
            setLoading(false);
            setOrderModelsList([]);
        }
    };

    const fetchAllowedRegStatuses = () => {
        setLoading(true);

        const errorHandler = (error) => {
            NotificationManager.error(
                error?.description || 'Error getting allowed statuses',
                'Error',
                4000
            );
            setLoading(false);
        };

        getAllowedRegStatuses({}, errorHandler).then((res) => {
            setLoading(false);
            if (res?.allowed_merchant_statuses)
                setAllowedMerchantRegStatuses(res.allowed_merchant_statuses);
            if (res?.allowed_user_statuses)
                setAllowedUserRegStatuses(res.allowed_user_statuses);
        });
    };

    const fetchRoles = async () => {
        setLoading(true);

        const resultHandler = (data) => {
            if (data && data.status === 'accept' && data.roles) {
                setRoles(data.roles);
            } else {
                setRoles([]);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setRoles([]);
            setLoading(false);
        };

        // TODO: Get appid here from localStorage
        getAdminRolesList({ appid: app?.id }, errorHandler).then((data) => {
            setLoading(false);
            return resultHandler(data);
        });
    };

    useEffect(() => {
        if (roles?.length) {
            const defaultOpt = {
                label: 'None',
                value: -1,
            };

            const newRoles = roles.map((item) => ({
                label: item.name,
                value: item._id,
            }));
            newRoles.push(defaultOpt);
            setRolesOptions(newRoles);
        }
    }, [roles]);

    useEffect(() => {
        if (orderModel) {
            getProductList({
                query_merchant: orderModel?.merchant,
                query_application: orderModel?.application,
                query_skip: 0,
                query_count: 10000,
            })
                .then((data) => {
                    const { product: productList, producttype: productTypes } =
                        data;
                    setProducts({ productList, productTypes });
                })
                .catch(console.error);
        }
    }, [orderModel]);

    useEffect(() => {
        if (orderModel) {
            getNotifyMessageList({
                merchant:
                    orderModel.merchant === 'None' ? null : orderModel.merchant,
            }).then(({ notify_messages }) => {
                setMessages(notify_messages);
            });
            fetchOrderModelList();
            fetchAllowedRegStatuses();
            fetchRoles();
        }
    }, [orderModel]);

    const onPublish = (statusData) => {
        if (rfInstance) {
            const { elements } = rfInstance.toObject();
            const nodes = elements.filter(isNode);
            const updated_nodes_data = nodes.map((e) => {
                const ind = statusData.findIndex((obj) => obj.id == e.id);
                if (ind >= 0) {
                    return { ...e, data: statusData[ind].data };
                } else {
                    return e;
                }
            });
            const statuses = updated_nodes_data.reduce((statuses, node) => {
                const can_change_to = getOutgoers(node, elements).map(
                    (outgoer) => outgoer.data.label
                );
                const status = getStatus({ ...node, can_change_to });
                return { ...statuses, [node.id]: status };
            }, {});
            const editedModel = prepareToPublish(
                orderModel,
                orderModelName,
                statuses,
                orderType,
                destinationGroup
            );

            onSave(editedModel);
        }
    };

    const onLoad = (instance) => {
        setRfInstance(instance);
    };

    const getRestrictStatuses = () => {
        if (
            orderType === 'user_registration' ||
            orderType === 'merchant_registration'
        )
            return true;
        return false;
    };

    const getStatusesOptions = () => {
        if (
            orderType === 'user_registration' &&
            allowedUserRegStatuses.length
        ) {
            return allowedUserRegStatuses;
        }
        if (
            orderType === 'merchant_registration' &&
            allowedMerchantRegStatuses.length
        ) {
            return allowedMerchantRegStatuses;
        }
        return [];
    };

    return (
        <div className="w-100 h-50 flex-column">
            <NotificationContainer />
            {loading ? (
                <Wait />
            ) : (
                nodes && (
                    <ReactFlowProvider>
                        <label htmlFor="name">Model's name</label>
                        <input
                            type="text"
                            className="form-control mb-4"
                            id="name"
                            defaultValue={orderModel.name}
                            required={true}
                            onChange={(e) => setOrderModelName(e.target.value)}
                        />

                        <label htmlFor="order_type">Order Type</label>
                        <Select
                            name="order_type"
                            className="form-control mb-4"
                            value={orderType}
                            onChange={handleChangeType}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="">
                                <em>&#8212; select order type &#8212;</em>
                            </MenuItem>
                            <ListSubheader>Common types</ListSubheader>
                            <MenuItem value="reservation">Reservation</MenuItem>
                            <MenuItem value="telia_authentication">Telia authentication</MenuItem>
                            <MenuItem value="purchase">Purchase order</MenuItem>
                            <ListSubheader>Registration types</ListSubheader>
                            <MenuItem value="user_registration">
                                User registration
                            </MenuItem>
                            <MenuItem value="merchant_registration">
                                Merchant registration
                            </MenuItem>
                            <ListSubheader>Ecommerce types</ListSubheader>
                            <MenuItem value="PAYMENT_ORDER_TYPE">
                                Ecommerce payment
                            </MenuItem>
                            <MenuItem value="DEPOSIT_ORDER_TYPE">
                                Ecommerce deposit
                            </MenuItem>
                            <MenuItem value="WITHDRAW_ORDER_TYPE">
                                Ecommerce withdraw
                            </MenuItem>
                            <MenuItem value="REFUND_ORDER_TYPE">
                                Ecommerce refund
                            </MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </Select>

                        <LayoutFlow
                            onLoad={onLoad}
                            onPublish={onPublish}
                            initialElements={nodes}
                            orderModel={orderModel}
                            restrictStatuses={getRestrictStatuses()}
                            allowedStatuses={getStatusesOptions()}
                            onDeleteNode={handleDeleteNode}
                            onResetNodes={resetNodes}
                        />
                        <ControlPanel
                            products={products.productList}
                            messages={messages}
                            serviceModels={orderModelsList}
                            setNodes={onSetNodes}
                            changeNodes={setNodes}
                            onDeleteNode={handleDeleteNode}
                            onResetNodes={resetNodes}
                        />
                    </ReactFlowProvider>
                )
            )}
        </div>
    );
};

export default OrderModelView;

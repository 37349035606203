import InvitationForRoleForm from './InvitationForRoleForm';
import useSearchString from '../../utils/useSearchString';
import { useState } from 'react';

const InvitationForRole = () => {
const { role_id, role_name } = useSearchString(); 
const [invitation, setInvitation] = useState({role_id: role_id, role_name: role_name, email: '', message: 'You are invited to join our Honkio team'});

console.log('invite', invitation)
  return (
    <div>
      <InvitationForRoleForm invitation={invitation} />
    </div>
  );
};

export default InvitationForRole;
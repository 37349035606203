import Honkio from '../../Honkio';

const getPaymentList = (options, errorHandler = {}) => {
    const { query_merchant } = options;
    return Honkio().mainShop.userFetch(
        'paymentproviderlist',
        {
            query_merchant,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
const deletePaymentProvider = (options, errorHandler = {}) => {
    const { id } = options;
    return Honkio().mainShop.userFetch(
        'paymentproviderset',
        {
            id,
            version: 3,
            action: 'delete',
        },
        errorHandler
    );
};
const getPaymentById = (options, errorHandler = {}) => {
    const { id } = options;
    return Honkio().mainShop.userFetch(
        'paymentproviderget',
        {
            id,
            version: 3,
            action: 'read',
        },
        errorHandler
    );
};
const updatePaymentProvider = (options, errorHandler = {}) => {
    const { id, title } = options;
    return Honkio().mainShop.userFetch(
        'paymentproviderset',
        {
            id,
            title,
            version: 3,
            action: 'update',
        },
        errorHandler
    );
};
const addPaymentProvider = (options, errorHandler = {}) => {
    const { title, merchant_id } = options;
    console.log(merchant_id);
    return Honkio().mainShop.userFetch(
        'paymentproviderset',
        {
            title,
            merchant_id,
            version: 3,
            action: 'create',
        },
        errorHandler
    );
};
export {
    getPaymentList,
    deletePaymentProvider,
    getPaymentById,
    updatePaymentProvider,
    addPaymentProvider,
};
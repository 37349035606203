import { Link } from 'react-router-dom';
import AuthPanel from './AuthPanel';
import LanguageSelector from './LanguageSelector';
import NotificationMenu from './NotificationMenu';
import Selected from './Selected';

const Navbar = () => (
  <nav className="navbar w-100 p-0 shadow-sm">
    <div className="container-fluid">
      <Link className="navbar-brand mr-auto" to="/">
        <img
          alt="Honkio"
          src="/logo.png"
          className="d-inline-block align-top mr-2"
        />
      </Link>
      <Selected />
      <LanguageSelector />
      <NotificationMenu className="ml-auto mr-2" />
      <AuthPanel />
    </div>
  </nav>
);

export default Navbar;

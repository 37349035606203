import { useEffect, useState } from 'react';
import getProduct from '../../api/utils/product/getProduct';
import {
    merchantDeleteProduct,
    merchantUpdateProduct,
} from '../../api/utils/product/setMerchantProduct';
import useSearchString from '../../utils/useSearchString';
import ProductEditForm from './ProductEditForm';
import {
    NotificationManager,
    NotificationContainer,
} from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal, Button } from 'react-bootstrap';
import BackButton from '../UI/BackButton';

const ProductEdit = () => {
    const history = useHistory();
    const [product, setProduct] = useState({ info: null, types: [] });
    const { appid, merchant } = useSearchString();
    const { product: id } = useSearchString();

    const [showConfirmRemoveProductType, setShowConfirmRemoveProductType] =
        useState(false);
    const handleShowConfirmRemoveProductType = () =>
        setShowConfirmRemoveProductType(true);
    const handleCloseConfirmRemoveProductType = () =>
        setShowConfirmRemoveProductType(false);
    const showConfirmDialog = () => {
        handleShowConfirmRemoveProductType();
    };

    useEffect(() => {
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };
        getProduct({ id }, errorHandler)
            .then((data) => {
                return data;
            })
            .then(({ product, types }) => ({ info: product, types }))
            .then(setProduct)
            .catch(console.error);
    }, [id]);

    const onSubmit = async (productData) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Saved');
                history.goBack();
            } else {
                NotificationManager.error('Error');
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };
        const payload = {
            ...productData,
            settings: {
                ...(productData?.settings ?? {}),
                int_bonus_value: productData?.settings?.int_bonus_value
                    ? productData?.settings?.int_bonus_value
                    : 0,
            },
        };

        try {
            const res = await merchantUpdateProduct(
                { id, ...payload, application: appid, merchant },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log(error);
        }
    };

    const onDelete = () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success('Product deleted');
                history.goBack();
            } else {
                NotificationManager.error('Error');
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        const productObject = product.info;
        productObject.product = { _id: productObject._id };
        productObject.id = productObject._id;

        merchantDeleteProduct(productObject, errorHandler)
            .then((data) => {
                return resultHandler(data);
            })
            .catch(console.error);
    };

    return (
        <div>
            <NotificationContainer />
            <h2>
                <BackButton to="/products" text="Back to list" /> Product edit
            </h2>
            {product?.info?._id ? (
                <ProductEditForm
                    id={id}
                    merchant={product.merchant}
                    onSubmit={onSubmit}
                    onDelete={() => {
                        showConfirmDialog();
                    }}
                    defaultValues={{ ...product.info, types: product.types }}
                />
            ) : (
                <span>Not found</span>
            )}
            <Modal
                show={showConfirmRemoveProductType}
                onHide={handleCloseConfirmRemoveProductType}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to remove product?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleCloseConfirmRemoveProductType}
                    >
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => onDelete()}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ProductEdit;

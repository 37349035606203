import { useContext } from 'react';
import NavigationContext from '../../contexts/NavigationContext';
import SidebarLink from './SidebarLink';
import SidebarLinkWithoutChildren from './SidebarLink';

const Sidebar = ({ config }) => {
    const { location, setLocation } = useContext(NavigationContext);
    return (
        <nav className="nav flex-column nav-pills sticky-top p-3 shadow-sm">
            <SidebarLink to="/dashboard" title="Dashboard"></SidebarLink>
            <SidebarLink to="/apps" title="Applications"></SidebarLink>
            <SidebarLink to="/merchants" title="Merchants"></SidebarLink>
            <SidebarLink
                to="/pointsOfSales"
                title="Points of sales"
            ></SidebarLink>
            <SidebarLink to="/warehouses" title="Warehouses"></SidebarLink>
            <SidebarLink to="/users" title="Users"></SidebarLink>
            <SidebarLink to="/merchant/role" title="User groups"></SidebarLink>
            <SidebarLink
                to="/accessrules/list"
                title="Access rules"
            ></SidebarLink>
            <SidebarLink to="/transactions" title="Transactions">
                <SidebarLink to="/transactions/psps" title="PSPS"></SidebarLink>
                <SidebarLink
                    to="/transactions/payments"
                    title="Payment"
                ></SidebarLink>
                <SidebarLink
                    to="/transactions/tokenization"
                    title="Tokenization"
                ></SidebarLink>
                <SidebarLink
                    to="/transactions/login"
                    title="Login"
                ></SidebarLink>
                <SidebarLink
                    to="/transactions/registration"
                    title="Registration"
                ></SidebarLink>
                <SidebarLink
                    to="/transactions/lostpassword"
                    title="Lost password"
                ></SidebarLink>
                <SidebarLink
                    to="/transactions/register_merchant"
                    title="Merchant registration"
                ></SidebarLink>
                <SidebarLink
                    to="/transactions/external"
                    title="External"
                ></SidebarLink>
            </SidebarLink>

            <SidebarLink to="/customers" title="Customers">
                <SidebarLink to="/customers" title="Customers" />
                <SidebarLink to="/customer/types" title="Customer types" />
            </SidebarLink>

            <SidebarLink to="/assetsmenu" title="Assets">
                <SidebarLink to="/assets" title="Assets"></SidebarLink>
                <SidebarLink
                    to="/assetstructures"
                    title="Asset structures"
                ></SidebarLink>
                <SidebarLink
                    to="/asset/types"
                    title="Asset types"
                ></SidebarLink>
            </SidebarLink>
            <SidebarLink to="/assetimages" title="Images"></SidebarLink>
            <SidebarLink to="/events" title="Events"></SidebarLink>
            <SidebarLink to="/orders" title="Orders"></SidebarLink>
            <SidebarLink to="/reservation" title="Reservations"></SidebarLink>
            <SidebarLink to="/reports" title="Reports">
                <SidebarLink
                    to="/product_report"
                    title="Product report"
                ></SidebarLink>
                <SidebarLink
                    to="/sales_report"
                    title="Sales report"
                ></SidebarLink>
            </SidebarLink>
            <SidebarLink to="/tools" title="Tools">
                <SidebarLink to="/tools" title="Tools"></SidebarLink>
                <SidebarLink
                    to="/data-importer"
                    title="Data import"
                ></SidebarLink>
                <SidebarLink
                    to="/data-importer/cold-calls"
                    title="Cold calls"
                ></SidebarLink>
                <SidebarLink to="/chat" title="Chat"></SidebarLink>
                <SidebarLink to="/debug" title="Debug"></SidebarLink>
                <SidebarLink
                    to="/debug/video-streaming"
                    title="Video streaming"
                ></SidebarLink>
            </SidebarLink>
            <SidebarLink to="/scheduler" title="Scheduler">
                <SidebarLink to="/scheduler" title="Scheduler"></SidebarLink>
                <SidebarLink
                    to="/scheduler/jobs-list"
                    title="Jobs"
                ></SidebarLink>
            </SidebarLink>
            <SidebarLink
                to="/merchant/user/registration-settings"
                title="User reg settings"
            ></SidebarLink>
            <SidebarLink to="/registration_models" title="Registration models">
                <SidebarLink
                    to="/registration_models/user"
                    title="User"
                ></SidebarLink>

                <SidebarLink
                    to="/registration_models/merchant"
                    title="Merchant"
                ></SidebarLink>
            </SidebarLink>
            <SidebarLink to="/registrations" title="Registrations">
                <SidebarLink
                    to="/registrations/merchant/documents_review"
                    title="Merchant docs review"
                ></SidebarLink>
                <SidebarLink
                    to="/registrations/merchant/approval"
                    title="Merchant approval"
                ></SidebarLink>
                <SidebarLink
                    to="/registrations/merchant/review_shareholders"
                    title="Review shareholders"
                ></SidebarLink>
            </SidebarLink>
            <SidebarLink to="/login_rules" title="Login rules"></SidebarLink>
            <SidebarLink to="/settings" title="Settings">
                <SidebarLink
                    to="/ordermodel"
                    title="Order models"
                ></SidebarLink>
                <SidebarLink to="/products/" title="Products"></SidebarLink>
                <SidebarLink
                    to="/products/types"
                    title="Product types"
                ></SidebarLink>
                <SidebarLink
                    to="/servicekeys"
                    title="Service keys"
                ></SidebarLink>
                <SidebarLink to="/password" title="Security"></SidebarLink>
                <SidebarLink to="/messages" title="Messages"></SidebarLink>
                <SidebarLink to="/greylists" title="Grey list"></SidebarLink>
                <SidebarLink
                    to="/velocityRules"
                    title="Velocity Rules"
                ></SidebarLink>
                <SidebarLink
                    to="/global_settings"
                    title="Global settings"
                ></SidebarLink>
                <SidebarLink
                    to="/merchant_register_app"
                    title="Merchant register"
                ></SidebarLink>
                <SidebarLink
                    to="/app_secrets"
                    title="App secrets"
                ></SidebarLink>
                <SidebarLink
                    to="/push-notifications-config"
                    title="Push notifications"
                ></SidebarLink>
            </SidebarLink>

            <SidebarLink to="/template-models" title="Template models">
                <SidebarLink
                    to="/template-models/roles"
                    title="Role templates"
                ></SidebarLink>
                <SidebarLink
                    to="/template-models/notifymessages"
                    title="Notifymessage templates"
                ></SidebarLink>
                <SidebarLink
                    to="/template-models/ordermodels"
                    title="Order model templates"
                ></SidebarLink>
                <SidebarLink
                    to="/template-models/products"
                    title="Product templates"
                ></SidebarLink>
            </SidebarLink>
            <SidebarLink to="/aml" title="AML"></SidebarLink>
            <SidebarLink to="/vouchers" title="Vouchers"></SidebarLink>
            <SidebarLink
                to="/payment"
                title="Payment service provider"
            ></SidebarLink>

        </nav>
    );
};

export default Sidebar;
